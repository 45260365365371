import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { authDataUser } from '../../actions/user';
import { localSet } from '../../lib/session';
import Loading from '../utils/loading';
import Model from '../../model/login';
import { modelToAuthViewModel } from '../../mapper/user';
import Config from '../../config/environments';
import LOGIN from '../../const/login';

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { ...Model, Redirected: false, ShowInfo: true };
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const state = modelToAuthViewModel(this.state);

    p.authDataUser(state);
    this.setState({ Redirected: true });
  }

  render() {
    const { authUser, history } = this.props;
    const { Redirected, Email, ShowInfo } = this.state;

    const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

    if (authUser && authUser.state !== false) {
      return <Loading msg={LOGIN.LOADING} />;
    }

    if (authUser && authUser.state === false && Redirected) {
      if (authUser && authUser.data) {
        localSet('P2B_BACKOFFICE_AUTH_TOKEN', authUser.data, Config.EXPIRATION_AUTH);
        localSet('P2B_BACKOFFICE_IS_LOGGED', 'true', Config.EXPIRATION_AUTH);
        return <Redirect to={{ pathname: '/' }} />;
      }
    }

    return (
      <div className="app back-logo flex-row align-items-center">
        {env === 'HML' && ShowInfo && (
          <>
            <Row className="identifier-environment top-0">
              <Col xs="1" />
              <Col xs="10" className="d-flex justify-content-center">
                Você está acessando o ambiente de teste !
              </Col>
              <Col xs="1" className="p-0">
                <Button
                  className="m-0 bg-transparent border-0"
                  type="button"
                  onClick={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                  onKeyDown={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                >
                  <i
                    className="fa fa-close text-white"
                  />
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 border-top">
                    <CardBody>
                      <Col md="12">
                        <div className="container-logo">
                          <div className="justify-content-left">
                            <Media
                              className="logo"
                              object
                              src="/assets/imgs/pay2b Logo_pay2b-07.png"
                              alt="Logo"
                              style={{ width: '100px' }}
                            />
                          </div>
                          <span className="title">{LOGIN.TITLE}</span>
                          <span className="sub-title">{LOGIN.SUB_TITLE}</span>
                        </div>
                      </Col>
                      <Row className="justify-content-center text-center">
                        <Col md="12">
                          <h1>{LOGIN.LOGIN_TEXT}</h1>
                        </Col>
                      </Row>
                      <hr />
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          maxLength="50"
                          onChange={(e) => this.setState({ Email: e.target.value })}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Senha"
                          required="required"
                          id="Password"
                          name="Password"
                          maxLength="256"
                          onChange={(e) => this.setState({ Password: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="12" className="text-right">
                          <a href="/#/esqueci-minha-senha" className="p-2" id="ForgotButton" name="ForgotButton">
                            {LOGIN.FORGOT_BUTTON}
                          </a>
                        </Col>
                        <Col xs="12">
                          <Button color="primary" className="px-4 bg-p2b border-0 mx-0" id="LoginButton" name="LoginButton" style={{ width: '100%' }}>
                            {LOGIN.LOGIN_BUTTON}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({ state: PropTypes.bool }).isRequired,
  authDataUser: PropTypes.func.isRequired,
  authUser: PropTypes.shape({ data: PropTypes.shape({}), state: PropTypes.bool }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    authDataUser,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
