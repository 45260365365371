export default {
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'https://backoffice.gateway.pay2b.io',
  EXPIRATION_AUTH: 3600000 || process.env.MAX_IDLE_TIME,
  EXPIRATION_FORM: 5, // Minuts
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true,
  },
  USER: {
    URL: '/security/user',
    USER: '/user',
    BY_EMAIL: '/byemail',
    RESET_PASSWORD: '/reset_password',
    AUTH: '/auth/login',
    VALIDATE_TOKEN: '/reset-password',
    RESET: '/reset-password',
    LOGOUT: '/auth/logout',
  },
  CATEGORY: {
    URL: '/category',
  },
  PERMISSION: {
    URL: '/security/permission',
  },
  GROUP: {
    URL: '/security/group',
  },
  PAYMENT: {
    URL: '/paymentmethod',
  },
  PAYMENT_OPTION: {
    URL: '/paymentmethod/options',
  },
  BANK: {
    URL: '/bank',
  },
  ACQUIRER: {
    URL: '/acquirer_error',
  },
  P2B: {
    URL: '/p2b_error',
  },
  OPTIN: {
    URL: '/optin',
  },
  SCHEDULE: {
    URL: '/receivable',
  },
  TRANSFER: {
    URL: '/receivable/transfer',
    CONFIRM: '/receivable',
    DOWNLOAD: '/transfer/download',
  },
  RECEIVABLE: {
    URL: '/receivable/',
    FILTER: '/receivable/filter',
    DOWNLOAD: '/receivable/download',
    INSTALLMENTS: '/receivable/installments',
    INSTALLMENTS_DOWNLOAD: '/receivable/installments/download',
  },
  STORE: {
    URL: '/store',
  },
  STORE_TYPE: {
    URL: '/store/type',
  },
  CUSTOMER: {
    URL: '/customer',
  },
  MERCHANT: {
    URL: '/merchant',
  },
  ORDER: {
    URL: '/order',
  },
  DASHBOARD: {
    URL: '/store',
    TRANSACTION_INDICATORS: '/transaction/indicators',
    TRANSACTION_ABSTRACT: '/transaction/abstract',
    FUTURE_PAYMENTS: '/receivable/monthly',
    RECEIVABLES: '/receivable',
    STORES_BY_FILTER: '/store',
    DASHBOARD: '/dashboard',
    SMAUG: '/smaug',
    PAYMENT: '/payment',
    BALANCE: '/balance',
  },
  BRAND: {
    URL: '/brand',
  },
  SHOPPING: {
    URL: '/shopping',
  },
  BANNER: {
    URL: '/banner',
  },
  OFFER: {
    URL: '/offer',
  },
  PARKING: {
    URL: '/parking',
    GET: '/parking/byfilter',
    TYPE: '/parking/type',
    MEDIA_TYPE: '/parking/mediatype',
  },
  WHITELABEL: {
    URL: '/white_label',
  },
  INSURANCE: {
    URL: '/insurance',
  },
};
