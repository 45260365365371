import { POST_INSURANCE, LIST_INSURANCE } from '../actionTypes/insurance';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostInsurance: BASE,
  listInsurance: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_INSURANCE:
      return { ...state, statePostInsurance: action.payload };
    case LIST_INSURANCE:
      return { ...state, listInsurance: action.payload };
    default:
      return state;
  }
};
