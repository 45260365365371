import { cnpj, cpf } from 'cpf-cnpj-validator';
import validator from 'validator';

export const cnpjValidator = () => {
  const inputs = document.getElementsByClassName('cnpj-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => {
        const { valid } = e.target.validity;
        const { required } = e.target;
        const size = nameInput.value.length;

        if (valid && required && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CNPJ inválido');
          return;
        }

        if (valid && !required && size > 0 && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CNPJ inválido');
          return;
        }

        nameInput.setCustomValidity('');
      });
    }
  }
};

export const cpfValidator = () => {
  const inputs = document.getElementsByClassName('cpf-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', (e) => {
        const { required } = e.target;
        const size = nameInput.value.length;

        if (required && !cpf.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CPF inválido');
          return;
        }

        if (!required && size > 0 && !cnpj.isValid(nameInput.value)) {
          nameInput.setCustomValidity('CPF inválido');
          return;
        }

        nameInput.setCustomValidity('');
      });
    }
  }
};

export const passwordValidator = (confirmPassword) => {
  const inputs = document.getElementsByClassName('password-validator');

  if (inputs.length > 0) {
    for (let index = 0; index < inputs.length; index += 1) {
      const nameInput = inputs[index];
      nameInput.addEventListener('input', () => {
        if (!validator.matches(nameInput.value, /.*[0-9].*/g)) {
          nameInput.setCustomValidity('A senha precisa ter pelo menos um número');
          return;
        }

        if (!validator.matches(nameInput.value, /[A-Z]/g)) {
          nameInput.setCustomValidity('A senha precisa ter pelo menos uma letra maiúscula');
          return;
        }

        if (!validator.matches(nameInput.value, /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g)) {
          nameInput.setCustomValidity('A senha precisa ter pelo menos um caractere especial');
          return;
        }

        if (!validator.isLength(nameInput.value, { min: 8 })) {
          nameInput.setCustomValidity('A senha precisa ter no mínimo 8 caracteres');
          return;
        }

        if (!validator.equals(nameInput.value, confirmPassword)) {
          nameInput.setCustomValidity('A senha não corresponde');
          return;
        }

        nameInput.setCustomValidity('');
      });
    }
  }
};
