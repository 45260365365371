import { POST_PARKING, LIST_PARKING } from '../actionTypes/parking';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostParking: BASE,
  listParking: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_PARKING:
      return { ...state, statePostParking: action.payload };
    case LIST_PARKING:
      return { ...state, listParking: action.payload };
    default:
      return state;
  }
};
