import { combineReducers } from 'redux';
import AcquirerErrorReducer from './acquirerErrorReducer';
import UserReducer from './userReducer';
import CategoryReducer from './categoryReducer';
import PermissionReducer from './permissionReducer';
import GroupReducer from './groupReducer';
import PaymentReducer from './paymentReducer';
import PaymentOptionReducer from './paymentOptionReducer';
import BankReducer from './bankReducer';
import ScheduleReducer from './scheduleReducer';
import TransferReducer from './transferReducer';
import ReceivableReducer from './receivableReducer';
import StoreReducer from './storeReducer';
import StoreTypeReducer from './storeTypeReducer';
import CustomerReducer from './customerReducer';
import OrderReducer from './orderReducer';
import DashboardReducer from './dashboardReducer';
import BrandReducer from './brandReducer';
import ShoppingReducer from './shoppingReducer';
import BannerReducer from './bannerReducer';
import OfferReducer from './offerReducer';
import ParkingReducer from './parkingReducer';
import ParkingTypeReducer from './parkingTypeReducer';
import ParkingMediaTypeReducer from './parkingMediaTypeReducer';
import WhitelabelReducer from './whitelabelReducer';
import InsuranceReducer from './insuranceReducer';
import P2BErrorReducer from './p2bErrorReducer';

export default (asyncReducers) => combineReducers({
  userState: UserReducer,
  acquirerErrorState: AcquirerErrorReducer,
  categoryState: CategoryReducer,
  permissionState: PermissionReducer,
  groupState: GroupReducer,
  paymentState: PaymentReducer,
  paymentOptionState: PaymentOptionReducer,
  bankState: BankReducer,
  scheduleState: ScheduleReducer,
  transferState: TransferReducer,
  receivableState: ReceivableReducer,
  storeState: StoreReducer,
  storeTypeState: StoreTypeReducer,
  shoppingState: ShoppingReducer,
  customerState: CustomerReducer,
  orderState: OrderReducer,
  dashboardState: DashboardReducer,
  brandState: BrandReducer,
  bannerState: BannerReducer,
  offerState: OfferReducer,
  parkingState: ParkingReducer,
  parkingTypeState: ParkingTypeReducer,
  parkingMediaTypeState: ParkingMediaTypeReducer,
  whitelabelState: WhitelabelReducer,
  insuranceState: InsuranceReducer,
  p2bErrorState: P2BErrorReducer,
  ...asyncReducers,
});
