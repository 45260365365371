import {
  AUTH_USER, POST_USER, LIST_USER, VALIDATE_TOKEN, RESET_PASSWORD,
} from '../actionTypes/user';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostUser: BASE,
  resetPassword: BASE,
  listUser: BASE_PAGINATION,
  authUser: BASE,
  validateToken: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authUser: action.payload };
    case RESET_PASSWORD:
      return { ...state, resetPassword: action.payload };
    case POST_USER:
      return { ...state, statePostUser: action.payload };
    case LIST_USER:
      return { ...state, listUser: action.payload };
    case VALIDATE_TOKEN:
      return { ...state, validateToken: action.payload };
    default:
      return state;
  }
};
