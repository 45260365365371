import { POST_BANNER, LIST_BANNER } from '../actionTypes/banner';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostBanner: BASE,
  listBanner: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_BANNER:
      return { ...state, statePostBanner: action.payload };
    case LIST_BANNER:
      return { ...state, listBanner: action.payload };
    default:
      return state;
  }
};
