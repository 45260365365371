import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown,
} from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setStatusAuth } from '../actions/user';
import { localRemove } from '../lib/session';

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.logoutHandle = this.logoutHandle.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.state = {
      Width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleResize() {
    this.setState({ Width: window.innerWidth });
  }

  logoutHandle(event) {
    event.preventDefault();

    const p = this.props;

    localRemove('P2B_BACKOFFICE_AUTH_TOKEN');
    localRemove('P2B_BACKOFFICE_IS_LOGGED');
    p.setStatusAuth();
  }

  render() {
    const { Width } = this.state;
    const { authUser } = this.props;
    const isMobile = Width < 999;

    if (!authUser) {
      return <Redirect to={{ pathname: '/login' }} />;
    }

    const model = authUser.data;

    return (
      <>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: '/assets/imgs/pay2b Logo_pay2b-07.png',
            width: 100,
            height: 'auto',
            alt: 'Logo',
          }}
          minimized={{
            src: '/assets/imgs/pay2b Logo_pay2b-07.png',
            width: 55,
            height: 'auto',
            alt: 'Logo',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown direction="down">
            <DropdownToggle nav>
              <span style={{ marginRight: 5 }}>
                <i className="fa fa-user" />
              </span>
              <span style={{
                width: isMobile ? 100 : 'auto', height: 20, overflow: 'hidden', display: 'inline-flex',
              }}
              >
                {' '}
                {model && model.Name ? model.Name : 'Admin'}
                {' '}
              </span>
              <span>
                <i className="fa fa-angle-down" />
              </span>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem onClick={this.logoutHandle}>
                <i className="fa fa-arrow-left" />
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </>
    );
  }
}

DefaultHeader.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  setStatusAuth: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.shape({
      Name: PropTypes.string,
    }),
    state: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setStatusAuth,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultHeader));
