import { POST_WHITELABEL, LIST_WHITELABEL } from '../actionTypes/whitelabel';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostWhiteLabel: BASE,
  listWhiteLabel: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_WHITELABEL:
      return { ...state, statePostWhiteLabel: action.payload };
    case LIST_WHITELABEL:
      return { ...state, listWhiteLabel: action.payload };
    default:
      return state;
  }
};
