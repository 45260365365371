import { POST_TRANSFER, LIST_TRANSFER, SEND_TRANSFER } from '../actionTypes/transfer';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostTransfer: BASE,
  listTransfer: BASE_PAGINATION,
  sendTransferConfirm: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_TRANSFER:
      return { ...state, statePostTransfer: action.payload };
    case LIST_TRANSFER:
      return { ...state, listTransfer: action.payload };
    case SEND_TRANSFER:
      return { ...state, sendTransferConfirm: action.payload };
    default:
      return state;
  }
};
