export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Atalhos',
      url: '/atalhos',
      icon: 'icon-home',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'APP',
      icon: 'fa fa-plus',
      grouping: true,
      badge: {
        variant: 'info',
      },
      children: [
        {
          name: 'Marcas',
          url: '/marcas',
          icon: 'fa fa-copyright',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Shoppings',
          url: '/shoppings',
          icon: 'fa fa-shopping-basket',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Banners',
          url: '/banners',
          icon: 'fa fa-flag',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Ofertas',
          url: '/ofertas',
          icon: 'fa fa-image',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Estacionamentos',
          url: '/estacionamentos',
          icon: 'fa fa-car',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Categorias',
          url: '/categorias',
          icon: 'fa fa-file',
          grouping: true,
          badge: {
            variant: 'info',
          },
        },
      ],
    },
    {
      name: 'Lojas',
      url: '/lojas',
      icon: 'fa fa-shopping-cart',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Agenda',
      url: '/agenda',
      icon: 'fa fa-clock-o',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Usuários',
      url: '/usuarios',
      icon: 'fa fa-users',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Permissões',
      url: '/permissoes',
      icon: 'fa fa-lock',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Grupos',
      url: '/grupos',
      icon: 'fa fa-cogs',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Clientes',
      url: '/clientes',
      icon: 'fa fa-users',
      badge: {
        variant: 'info',
      },
    },
    // {
    //   name: 'Acessos',
    //   url: '/acessos',
    //   icon: 'fa fa-users',
    //   badge: {
    //     variant: 'info',
    //   },
    // },
    {
      name: 'Meios de Pagamentos',
      url: '/meios-pagamentos',
      icon: 'fa fa-credit-card',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Bancos',
      url: '/bancos',
      icon: 'fa fa-bank',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Pedidos',
      url: '/pedidos',
      icon: 'fa fa-th-list',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Seguros',
      url: '/seguros',
      icon: 'fa fa-car',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Whitelabel',
      url: '/whitelabel',
      icon: 'fa fa-bank',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Retornos Adquirentes',
      url: '/retorno-adquirentes',
      icon: 'fa fa-exclamation-triangle',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Retornos Pay2b',
      url: '/p2b-retornos',
      icon: 'fa fa-exclamation-triangle',
      badge: {
        variant: 'info',
      },
    },
  ],
};
