import './middlewares/polyfill';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactTableDefaults } from 'react-table-6';
import { unregister } from './middlewares/registerServiceWorker';
import createStore from './store/store';
import DefaultLayout from './containers';
import Login from './views/pages/login';
import Forgot from './views/pages/forgot';
import Reset from './views/pages/reset';
import Page404 from './views/pages/page404';
import Page500 from './views/pages/page500';
import PrivateRoute from './views/utils/privateRoute';
import TableDefaults from './config/table/table';
import 'moment/locale/pt-br';
import Meta from './lib/meta';
import packageJson from '../package.json';

import 'react-table-6/react-table.css';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.scss';
import './scss/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

// eslint-disable-next-line no-underscore-dangle
const store = createStore(window.__INITIAL_STATE__);

Object.assign(ReactTableDefaults, TableDefaults);

if (process.env.REACT_APP_STAGE !== 'PROD') {
  Meta();
}

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ToastContainer autoClose={8000} />
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/senha/:token" name="Reset Password Page" component={Reset} />
          <Route exact path="/esqueci-minha-senha/:invalidToken?" name="Reset Password Page" component={Forgot} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
      <span className="version">
        v
        {packageJson.version}
      </span>
    </div>
  </Provider>,
  document.getElementById('root'),
);
unregister();
