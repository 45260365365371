import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Form,
  Media,
  Label,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { resetPasswordDataUser } from '../../actions/user';
import Loading from '../utils/loading';
import Model from '../../model/forgot';
import FORGOT from '../../const/forgot';

class Recover extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { ...Model, Redirected: false };
  }

  handleSubmit(event) {
    event.preventDefault();

    const p = this.props;
    const { Email } = this.state;

    const state = encodeURIComponent(Email);

    p.resetPasswordDataUser(state);
    this.setState({ Redirected: true });
  }

  render() {
    const { authUser } = this.props;
    const { Redirected, Email } = this.state;

    if (authUser && authUser.state !== false) {
      return <Loading msg={FORGOT.LOADING} />;
    }

    if (authUser && authUser.state === false && Redirected) {
      if (authUser && authUser.data) {
        return <Redirect to={{ pathname: '/' }} />;
      }
    }

    const { match } = this.props;
    const { invalidToken } = match.params;

    return (
      <div className="app back-logo flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4 border-top">
                    <CardBody>
                      <Col md="12">
                        <div className="container-logo">
                          <div className="justify-content-left">
                            <Media
                              className="logo"
                              object
                              src="/assets/imgs/pay2b Logo_pay2b-07.png"
                              alt="Logo"
                              style={{ width: '100px' }}
                            />
                          </div>
                          <span className="title">{FORGOT.TITLE}</span>
                          <span className="sub-title">{FORGOT.SUB_TITLE}</span>
                        </div>
                      </Col>
                      <Row className="justify-content-center text-center">
                        <Col md="12">
                          {
                            invalidToken
                              ? <h1 style={{ color: '#ee4250' }}>{FORGOT.FORGOT_INVALID_TEXT}</h1>
                              : <h1>{FORGOT.FORGOT_TEXT}</h1>
                          }
                        </Col>
                      </Row>
                      <hr />
                      <Row className="justify-content-center text-center">
                        <Col md="12 mb-3">
                          <Label style={{ fontSize: '18px' }}>{FORGOT.FORGOT_SUB_TEXT}</Label>
                        </Col>
                      </Row>
                      <InputGroup className="mb-5">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          required="required"
                          id="Email"
                          name="Email"
                          value={Email}
                          maxLength="50"
                          onChange={(e) => this.setState({ Email: e.target.value })}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6" className="float-left">
                          <Button color="primary" className="px-4 m-0 bg-p2b border-0" id="ConfirmButton" name="ConfirmButton" style={{ width: '100%' }}>
                            {FORGOT.CONFIRM_BUTTON}
                          </Button>
                        </Col>
                        <Col xs="6" className="float-left">
                          <a href="/#/login" className="px-4 m-0 btn btn-secondary" id="CancelButton" name="CancelButton" style={{ width: '100%' }}>
                            {FORGOT.CANCEL_BUTTON}
                          </a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

Recover.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({}) }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invalidToken: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  resetPasswordDataUser: PropTypes.func.isRequired,
  authUser: PropTypes.shape({ data: PropTypes.shape({}), state: PropTypes.bool }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    resetPasswordDataUser,
  },
  dispatch,
);

const mapStateToProps = (state) => ({
  authUser: state.userState.authUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(Recover);
