import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import {
  AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm,
  AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav,
} from '@coreui/react';
import navigation from '../routes/nav';
import routes from '../routes/routes';
import DefaultFooter from './defaultFooter';
import DefaultHeader from './defaultHeader';

class DefaultLayout extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ShowInfo: true, PageName: '',
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { history } = nextProps;
    const { PageName, ShowInfo } = nextState;

    if (history.location.pathname !== PageName && PageName.length > 0 && !ShowInfo) {
      this.setState({ ShowInfo: true });

      return false;
    }

    return true;
  }

  render() {
    const { ShowInfo } = this.state;
    const { history } = this.props;
    const env = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE.trim();

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        {env === 'HML' && ShowInfo && (
          <>
            <div className="container-identifier" />
            <Row className="identifier-environment">
              <Col xs="1" />
              <Col xs="10" className="d-flex justify-content-center">
                Você está acessando o ambiente de teste !
              </Col>
              <Col xs="1" className="p-0">
                <Button
                  className="m-0 bg-transparent border-0"
                  type="button"
                  onClick={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                  onKeyDown={() => {
                    this.setState({ ShowInfo: false, PageName: history.location.pathname });
                  }}
                >
                  <i
                    className="fa fa-close text-white"
                  />
                </Button>
              </Col>
            </Row>
          </>
        )}
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route) => (route.component ? (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <route.component {...props} />
                    )}
                  />
                )
                  : (null)))}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
