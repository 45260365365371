import moment from 'moment';
import weekUtil from '../lib/weekUtil';

const dashboardStateModel = {
  Uuid: '',
  FantasyName: '',
  DashboardCancel: [],
  pagination: {
    quantity: [5, 10, 20, 50, 100],
    count: 0,
    size: 10,
  },
  filter: {
    searchStores: [],
    selectedStores: [],
  },
  transactionIndicators: {
    count: 0,
    total: 0,
    average: 0,
  },
  transactionAbstract: {
    total: 0,
    authorized: 0,
    canceled: 0,
    others: 0,
  },
  futurePayments: {
    list: Array(5)
      .fill(null)
      .map(
        (_, i) => ({
          date: moment(new Date()).add(i, 'M').format('YYYY-MM'),
          netValue: 0,
        }),
      ),
  },
  dashboardDatas: {},
  mediumPrize: {},
  receivables: {
    list: Array(5)
      .fill(null)
      .map(
        (_, i) => ({
          date: moment(weekUtil().startDate).add(i, 'days').format('YYYY-MM-DD'),
          netValue: 0,
          semaphore: 'NONE',
          isToday: moment(weekUtil().startDate).add(i, 'days').format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD'),
        }),
      ),
  },
  Tabs: [
    { title: 'Recorrência', tabValue: '1', requests: ['FUTURE_RELEASES_RECURRING', 'TOTAL_TRANSACTIONS_RECURRING', 'TRANSACTED_AMOUNT_RECURRING', 'AVERAGE_TICKET_RECURRING', 'NOTIFICATIONS_RECURRING', 'VALUE_FEES_RECURRING', 'RECEIVABLE_INSTALLMENTS_RECURRING', 'REFUSED_INSTALLMENTS_RECURRING', 'PAYMENT_METHODS_RECURRING', 'RECURRENCE_STATUS_RECURRING', 'MOST_USED_BRAND_RECURRING', 'APPROVAL_RATING_RECURRING', 'PAYMENT_STATUS_RECURRING', 'INSTALLMENT_AMOUNT_RECURRING', 'VOLUME_DAY_WEEK_RECURRING', 'REASONS_REFUSAL_RECURRING'] },
    { title: 'Rotativo', tabValue: '2', requests: ['FUTURE_RELEASES_ROTARY', 'TOTAL_TRANSACTIONS_ROTARY', 'TRANSACTED_AMOUNT_ROTARY', 'AVERAGE_TICKET_ROTARY', 'REASONS_REFUSAL_ROTARY', 'MOST_USED_BRAND_ROTARY', 'VOLUME_DAY_WEEK_ROTARY', 'PAYMENT_METHODS_ROTARY', 'RECURRENCE_STATUS_ROTARY', 'APPROVAL_RATING_ROTARY'] },
  ],
  ActiveTab: '1',
  Requested: false,
};

export default dashboardStateModel;
