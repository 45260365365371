const moment = require('moment');

export default function weekUtil() {
  const today = moment(new Date());
  const weekDay = parseInt(today.format('d'), 0);
  let startDate;
  if (weekDay === 0) {
    startDate = today.add(1, 'days');
  } else if (weekDay === 6) {
    startDate = today.add(2, 'days');
  } else {
    startDate = today.subtract(weekDay - 1, 'days');
  }
  const endDate = moment(today).add(4, 'days');
  return { startDate, endDate };
}
