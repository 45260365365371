export default {
  LOADING: 'Carregando...',
  TITLE: 'Bem vindo ao',
  SUB_TITLE: 'Backoffice',
  RESET: 'Login',
  RESET_TEXT: 'Olá, administrador!',
  RESET_SUB_TEXT: 'Crie sua nova senha',
  TITLE_VALIDATION: 'Sua senha deve conter no mínimo',
  NOTIFY_SUCCESS: 'Senha alterada com sucesso!',
  RESET_BUTTON: 'Confirmar',
  FORGOT_BUTTON: 'Esqueceu sua senha?',
  FORGOT_MSG: 'Para recuperar sua senha, entre com contato com o Administrador do Sistema.',
};
