import Loadable from 'react-loadable';
import Loading from '../views/utils/loading';
import DefaultLayout from '../containers/defaultLayout';

const Home = Loadable({
  loader: () => import('../views/home/home'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('../views/dashboard/dashboard'),
  loading: Loading,
});

const UserList = Loadable({
  loader: () => import('../views/user/userList'),
  loading: Loading,
});

const Schedule = Loadable({
  loader: () => import('../views/schedule/schedule'),
  loading: Loading,
});

const UserForm = Loadable({
  loader: () => import('../views/user/userForm'),
  loading: Loading,
});

const CategoryList = Loadable({
  loader: () => import('../views/category/categoryList'),
  loading: Loading,
});

const CategoryForm = Loadable({
  loader: () => import('../views/category/categoryForm'),
  loading: Loading,
});

const PermissionList = Loadable({
  loader: () => import('../views/permission/permissionList'),
  loading: Loading,
});

const PermissionForm = Loadable({
  loader: () => import('../views/permission/permissionForm'),
  loading: Loading,
});

const GroupList = Loadable({
  loader: () => import('../views/group/groupList'),
  loading: Loading,
});

const GroupForm = Loadable({
  loader: () => import('../views/group/groupForm'),
  loading: Loading,
});

const PaymentList = Loadable({
  loader: () => import('../views/payment/paymentList'),
  loading: Loading,
});

const PaymentForm = Loadable({
  loader: () => import('../views/payment/paymentForm'),
  loading: Loading,
});

const BankList = Loadable({
  loader: () => import('../views/bank/bankList'),
  loading: Loading,
});

const BankForm = Loadable({
  loader: () => import('../views/bank/bankForm'),
  loading: Loading,
});

const TransferConfirm = Loadable({
  loader: () => import('../views/transfer/transferConfirm'),
  loading: Loading,
});

const ReceivableList = Loadable({
  loader: () => import('../views/receivable/receivableList'),
  loading: Loading,
});

const OrderDetail = Loadable({
  loader: () => import('../views/order/orderDetail'),
  loading: Loading,
});

const ReceivableDetail = Loadable({
  loader: () => import('../views/receivable/receivableDetail'),
  loading: Loading,
});

const InstallmentList = Loadable({
  loader: () => import('../views/receivable/installmentList'),
  loading: Loading,
});

const StoreList = Loadable({
  loader: () => import('../views/store/storeList'),
  loading: Loading,
});

const StoreFormGeneral = Loadable({
  loader: () => import('../views/store/storeFormGeneral'),
  loading: Loading,
});

const StoreFormAddress = Loadable({
  loader: () => import('../views/store/storeFormAddress'),
  loading: Loading,
});

const StoreFormImages = Loadable({
  loader: () => import('../views/store/storeFormImages'),
  loading: Loading,
});

const StoreFormFinancial = Loadable({
  loader: () => import('../views/store/storeFormFinancial'),
  loading: Loading,
});

const StoreFormResume = Loadable({
  loader: () => import('../views/store/storeFormResume'),
  loading: Loading,
});

const CustomerList = Loadable({
  loader: () => import('../views/customer/customerList'),
  loading: Loading,
});

const CustomerForm = Loadable({
  loader: () => import('../views/customer/customerForm'),
  loading: Loading,
});

const OrderList = Loadable({
  loader: () => import('../views/order/orderList'),
  loading: Loading,
});

const BrandList = Loadable({
  loader: () => import('../views/brand/brandList'),
  loading: Loading,
});

const BrandForm = Loadable({
  loader: () => import('../views/brand/brandForm'),
  loading: Loading,
});

const ShoppingList = Loadable({
  loader: () => import('../views/shopping/shoppingList'),
  loading: Loading,
});

const ShoppingForm = Loadable({
  loader: () => import('../views/shopping/shoppingForm'),
  loading: Loading,
});

const BannerList = Loadable({
  loader: () => import('../views/banner/bannerList'),
  loading: Loading,
});

const BannerForm = Loadable({
  loader: () => import('../views/banner/bannerForm'),
  loading: Loading,
});

const OfferList = Loadable({
  loader: () => import('../views/offer/offerList'),
  loading: Loading,
});

const OfferForm = Loadable({
  loader: () => import('../views/offer/offerForm'),
  loading: Loading,
});

const ParkingList = Loadable({
  loader: () => import('../views/parking/parkingList'),
  loading: Loading,
});

const ParkingForm = Loadable({
  loader: () => import('../views/parking/parkingForm'),
  loading: Loading,
});

const WhitelabelList = Loadable({
  loader: () => import('../views/whitelabel/whitelabelList'),
  loading: Loading,
});

const WhitelabelForm = Loadable({
  loader: () => import('../views/whitelabel/whitelabelForm'),
  loading: Loading,
});

const Access = Loadable({
  loader: () => import('../views/access/access'),
  loading: Loading,
});

const AcquirerError = Loadable({
  loader: () => import('../views/acquirerError/acquirerError'),
  loading: Loading,
});

const P2BError = Loadable({
  loader: () => import('../views/p2bError/p2bError'),
  loading: Loading,
});

const InsuranceList = Loadable({
  loader: () => import('../views/insurance/insuranceList'),
  loading: Loading,
});


const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: DefaultLayout,
  },
  {
    path: '/atalhos',
    name: 'Atalhos',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/agenda',
    exact: true,
    name: 'Agenda',
    component: Schedule,
  },
  {
    path: '/usuarios',
    exact: true,
    name: 'Usuários',
    component: UserList,
  },
  {
    path: '/usuarios/form',
    name: 'Formulário',
    component: UserForm,
  },
  {
    path: '/categorias',
    exact: true,
    name: 'Categorias',
    component: CategoryList,
  },
  {
    path: '/categorias/form',
    name: 'Formulário',
    component: CategoryForm,
  },
  {
    path: '/permissoes',
    exact: true,
    name: 'Permissões',
    component: PermissionList,
  },
  {
    path: '/permissoes/form',
    name: 'Formulário',
    component: PermissionForm,
  },
  {
    path: '/grupos',
    exact: true,
    name: 'Grupos',
    component: GroupList,
  },
  {
    path: '/grupos/form',
    name: 'Formulário',
    component: GroupForm,
  },
  {
    path: '/meios-pagamentos',
    exact: true,
    name: 'Meios de Pagamentos',
    component: PaymentList,
  },
  {
    path: '/meios-pagamentos/form',
    name: 'Formulário',
    component: PaymentForm,
  },
  {
    path: '/bancos',
    exact: true,
    name: 'Bancos',
    component: BankList,
  },
  {
    path: '/bancos/form',
    name: 'Formulário',
    component: BankForm,
  },
  {
    path: '/transferencias/confirm',
    name: 'Confirmação',
    component: TransferConfirm,
  },
  {
    path: '/repasses',
    exact: true,
    name: 'Repasses',
    component: ReceivableList,
  },
  {
    path: '/pedidos/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    component: OrderDetail,
  },
  {
    path: '/repasses/parcelas/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    component: ReceivableDetail,
  },
  {
    path: '/repasses/parcelas/:uuid',
    exact: true,
    name: 'Vendas',
    component: InstallmentList,
  },
  {
    path: '/agenda/parcelas/:uuid',
    exact: true,
    name: 'Vendas',
    component: InstallmentList,
  },
  {
    path: '/agenda/parcelas/detalhes/:uuid/:code',
    exact: true,
    name: 'Detalhes',
    component: ReceivableDetail,
  },
  {
    path: '/lojas',
    name: 'Lista de Lojas',
    exact: true,
    component: StoreList,
  },
  {
    path: '/lojas/cadastro/geral',
    name: 'Cadastro de Loja - Geral',
    exact: true,
    component: StoreFormGeneral,
  },
  {
    path: '/lojas/cadastro/endereco',
    name: 'Cadastro de Loja - Endereço',
    exact: true,
    component: StoreFormAddress,
  },
  {
    path: '/lojas/cadastro/imagens',
    name: 'Cadastro de Loja - Imagens',
    exact: true,
    component: StoreFormImages,
  },
  {
    path: '/lojas/cadastro/financeiro',
    name: 'Cadastro de Loja - Financeiro',
    component: StoreFormFinancial,
    exact: true,
  },
  {
    path: '/lojas/cadastro/resumo',
    name: 'Cadastro de Loja - Resumo',
    exact: true,
    component: StoreFormResume,
  },
  {
    path: '/lojas/edicao/geral/:uuid',
    name: 'Edição de Loja - Geral',
    exact: true,
    component: StoreFormGeneral,
  },
  {
    path: '/lojas/edicao/endereco/:uuid',
    name: 'Edição de Loja - Endereço',
    exact: true,
    component: StoreFormAddress,
  },
  {
    path: '/lojas/edicao/imagens/:uuid',
    name: 'Edição de Loja - Imagens',
    exact: true,
    component: StoreFormImages,
  },
  {
    path: '/lojas/edicao/financeiro/:uuid',
    name: 'Edição de Loja - Financeiro',
    component: StoreFormFinancial,
    exact: true,
  },
  {
    path: '/clientes',
    exact: true,
    name: 'Clientes',
    component: CustomerList,
  },
  {
    path: '/clientes/form',
    exact: true,
    name: 'Formulário',
    component: CustomerForm,
  },
  {
    path: '/pedidos',
    exact: true,
    name: 'Pedidos',
    component: OrderList,
  },
  {
    path: '/marcas',
    exact: true,
    name: 'Marcas',
    component: BrandList,
  },
  {
    path: '/marcas/form',
    exact: true,
    name: 'Formulário',
    component: BrandForm,
  },
  {
    path: '/shoppings',
    exact: true,
    name: 'Shoppings',
    component: ShoppingList,
  },
  {
    path: '/shoppings/form',
    exact: true,
    name: 'Formulário',
    component: ShoppingForm,
  },
  {
    path: '/banners',
    exact: true,
    name: 'Banners',
    component: BannerList,
  },
  {
    path: '/banners/form',
    exact: true,
    name: 'Formulário',
    component: BannerForm,
  },
  {
    path: '/ofertas',
    exact: true,
    name: 'Ofertas',
    component: OfferList,
  },
  {
    path: '/ofertas/form',
    exact: true,
    name: 'Formulário',
    component: OfferForm,
  },
  {
    path: '/estacionamentos',
    exact: true,
    name: 'Estacionamentos',
    component: ParkingList,
  },
  {
    path: '/estacionamentos/form',
    exact: true,
    name: 'Formulário',
    component: ParkingForm,
  },
  {
    path: '/whitelabel',
    exact: true,
    name: 'Whitelabel',
    component: WhitelabelList,
  },
  {
    path: '/whitelabel/form',
    exact: true,
    name: 'Formulário',
    component: WhitelabelForm,
  },
  {
    path: '/acessos',
    exact: true,
    name: 'Acessos',
    component: Access,
  },
  {
    path: '/retorno-adquirentes',
    exact: true,
    name: 'Retornos Adiquirentes',
    component: AcquirerError,
  },
  {
    path: '/p2b-retornos',
    exact: true,
    name: 'Retornos Pay2B',
    component: P2BError,
  },
  {
    path: '/seguros',
    exact: true,
    name: 'Seguros',
    component: InsuranceList,
  },
];

export default routes;
