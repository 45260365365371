import { POST_PERMISSION, LIST_PERMISSION } from '../actionTypes/permission';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  statePostPermission: BASE,
  listPermission: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_PERMISSION:
      return { ...state, statePostPermission: action.payload };
    case LIST_PERMISSION:
      return { ...state, listPermission: action.payload };
    default:
      return state;
  }
};
