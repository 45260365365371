import React from 'react';

function DefaultFooter() {
  return (
    <div className="w-100 d-flex justify-content-center">
      <a href="https://www.pay2b.com.br/" target="_blank" rel="noopener noreferrer">
        <img src="/assets/imgs/pay2b Logo_pay2b-07.png" alt="Logo" width={75} />
      </a>
    </div>
  );
}

export default DefaultFooter;
