import { POST_BANK, LIST_BANK } from '../actionTypes/bank';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  statePostBank: BASE,
  listBank: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_BANK:
      return { ...state, statePostBank: action.payload };
    case LIST_BANK:
      return { ...state, listBank: action.payload };
    default:
      return state;
  }
};
