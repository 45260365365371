import { POST_STORE, LIST_STORE, GET_STORE } from '../actionTypes/store';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  statePostStore: BASE,
  listStore: BASE,
  storeById: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_STORE:
      return { ...state, statePostStore: action.payload };
    case LIST_STORE:
      return { ...state, listStore: action.payload, totalStore: action.total };
    case GET_STORE:
      return { ...state, storeById: action.payload };
    default:
      return state;
  }
};
