import { POST_OFFER, LIST_OFFER } from '../actionTypes/offer';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostOffer: BASE,
  listOffer: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_OFFER:
      return { ...state, statePostOffer: action.payload };
    case LIST_OFFER:
      return { ...state, listOffer: action.payload };
    default:
      return state;
  }
};
