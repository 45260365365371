import LIST_PARKING_TYPE from '../actionTypes/parkingType';

const BASE = {
  state: false,
  data: null,
};

const INITIAL_STATE = {
  listParkingType: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LIST_PARKING_TYPE:
      return { ...state, listParkingType: action.payload };
    default:
      return state;
  }
};
