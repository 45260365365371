import { POST_CUSTOMER, LIST_CUSTOMER } from '../actionTypes/customer';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostCustomer: BASE,
  listCustomer: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CUSTOMER:
      return { ...state, statePostCustomer: action.payload };
    case LIST_CUSTOMER:
      return { ...state, listCustomer: action.payload };
    default:
      return state;
  }
};
