import {
  POST_P2B_ERROR, LIST_P2B_ERROR, DELETE_P2B_ERROR,
} from '../actionTypes/p2bError';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostP2BError: BASE,
  listP2BError: BASE_PAGINATION,
  stateDeleteP2BError: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_P2B_ERROR:
      return { ...state, statePostP2BError: action.payload };
    case LIST_P2B_ERROR:
      return { ...state, listP2BError: action.payload };
    case DELETE_P2B_ERROR:
      return { ...state, stateDeleteP2BError: action.payload };
    default:
      return state;
  }
};
