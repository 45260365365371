import { POST_SHOPPING, LIST_SHOPPING } from '../actionTypes/shopping';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostShopping: BASE,
  listShopping: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_SHOPPING:
      return { ...state, statePostShopping: action.payload };
    case LIST_SHOPPING:
      return { ...state, listShopping: action.payload };
    default:
      return state;
  }
};
