import { POST_BRAND, LIST_BRAND } from '../actionTypes/brand';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostBrand: BASE,
  listBrand: BASE_PAGINATION,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_BRAND:
      return { ...state, statePostBrand: action.payload };
    case LIST_BRAND:
      return { ...state, listBrand: action.payload };
    default:
      return state;
  }
};
