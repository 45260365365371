import {
  POST_ACQUIRER_ERROR, LIST_ACQUIRER_ERROR, DELETE_ACQUIRER_ERROR,
} from '../actionTypes/acquirerError';

const BASE = {
  state: false,
  data: null,
};

const BASE_PAGINATION = {
  state: false,
  data: {
    total: 0,
    list: [],
  },
};

const INITIAL_STATE = {
  statePostAcquirerError: BASE,
  listAcquirerError: BASE_PAGINATION,
  stateDeleteAcquirerError: BASE,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_ACQUIRER_ERROR:
      return { ...state, statePostAcquirerError: action.payload };
    case LIST_ACQUIRER_ERROR:
      return { ...state, listAcquirerError: action.payload };
    case DELETE_ACQUIRER_ERROR:
      return { ...state, stateDeleteAcquirerError: action.payload };
    default:
      return state;
  }
};
