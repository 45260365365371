export const TRANSACTION_INDICATORS = 'TRANSACTION_INDICATORS';
export const TRANSACTION_ABSTRACT = 'TRANSACTION_ABSTRACT';
export const FUTURE_PAYMENTS = 'FUTURE_PAYMENTS';
export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_CANCEL = 'DASHBOARD_CANCEL';
export const RECEIVABLES = 'RECEIVABLES';
export const STORES_BY_FILTER = 'STORES_BY_FILTER';

export const LIST_BALANCE = 'LIST_BALANCE';
export const LIST_DASHBOARD = 'LIST_DASHBOARD';
