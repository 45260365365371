import { axiosInstance as axios, axiosLogin } from '../lib/axios';
import Config from '../config/environments';
import { viewModelToModel, viewsModelToModels } from '../mapper/user';
import {
  AUTH_USER, LIST_USER, POST_USER, VALIDATE_TOKEN, RESET_PASSWORD,
} from '../actionTypes/user';
import updateState from './common/common';

export const getDataUser = (data, state) => async (dispatch) => {
  const base = {
    type: LIST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}${data}`;

  axios.get(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewsModelToModels(base.payload.data, state);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const authDataUser = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = Config.USER.AUTH;

  axiosLogin.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const resetPasswordDataUser = (data) => async (dispatch) => {
  const base = {
    type: RESET_PASSWORD,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.VALIDATE_TOKEN}${Config.MERCHANT.URL}${Config.USER.USER}${Config.USER.BY_EMAIL}/${data}`;

  axios.put(url).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const validateDataToken = (data) => async (dispatch) => {
  const base = {
    type: VALIDATE_TOKEN,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.VALIDATE_TOKEN}/${data}`;

  axiosLogin.get(url).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const resetDataPassword = (data, token) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    state: true,
    success: true,
    payload: {},
  };

  const url = `${Config.USER.RESET}/${token}`;

  axiosLogin.post(url, data).then((response) => {
    base.payload = updateState(response);
    base.payload.data = viewModelToModel(base.payload.data);
  }).catch((error) => {
    base.payload = error;
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const setStatusAuth = (data) => async (dispatch) => {
  const base = {
    type: AUTH_USER,
    payload: { data, state: false },
  };

  dispatch(base);
};

export const postDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = Config.USER.URL;

  axios.post(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};

export const putDataUser = (data) => async (dispatch) => {
  const base = {
    type: POST_USER,
    payload: {},
    state: true,
    success: true,
  };

  const url = `${Config.USER.URL}/${data.uuid}`;

  axios.put(url, data).then((response) => {
    base.payload = updateState(response);
  }).catch((error) => {
    base.payload = (error);
  }).finally(() => {
    dispatch(base);
  });

  dispatch(base);
};
