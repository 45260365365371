import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { localGet } from '../../lib/session';
import { setStatusAuth } from '../../actions/user';
import Loading from './loading';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Redirected: false,
    };
  }

  componentDidMount() {
    const p = this.props;
    const { authUser } = this.props;

    const auth = !authUser.state && authUser.data != null;
    const sessionAuth = localGet('P2B_BACKOFFICE_AUTH_TOKEN');

    if (!auth && sessionAuth) {
      p.setStatusAuth(sessionAuth);
    }
    this.setState({ Redirected: true });
  }

  render() {
    const { Redirected } = this.state;
    const {
      name, path, location, computedMatch, component, authUser,
    } = this.props;

    const rest = {
      name,
      path,
      location,
      computedMatch,
    };

    if ((authUser && authUser.state !== false) || !Redirected) {
      return <Loading msg="Carregando.." />;
    }

    const auth = authUser && authUser.data;

    return (
      <div>
        { auth ? (
          <Route {...rest} component={component} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )}
      </div>
    );
  }
}

PrivateRoute.propTypes = {
  location: PropTypes.shape({}).isRequired,
  computedMatch: PropTypes.shape({}).isRequired,
  component: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  setStatusAuth: PropTypes.func.isRequired,
  authUser: PropTypes.shape({
    data: PropTypes.object,
    state:
    PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({ setStatusAuth }, dispatch);

const mapStateToProps = state => ({
  authUser: state.userState.authUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
